import {cssVal, getDataByCodePostal, getDataBySiren, newInput, tryToDate} from "@/data/helpers";
import {def} from "@/data/dictionnaire";


export const clotureLiquidation = {
    title: "Clôture de liquidation",
    texte: "<strong>{{societeGroup.societeDenomination}} [[({{societeGroup.sigle}})]]</strong> {{societeGroup.societeFormeJuridique}}, au capital de {{societeGroup.societeCapital}} €, Siège : {{siegeSocialGroup.societeAdresse}} [[{{siegeSocialGroup.societeAdresse2}}]] {{siegeSocialGroup.societeCodePostal}} {{siegeSocialGroup.societeVille}}, RCS : {{societeGroup.societeRcsVille}} N°{{societeGroup.societeRcs}}.<br>Selon {{acteGroup.auTermeAssembleDecision}} en date du {{acteGroup.dateDu}}, il a été approuvé les comptes définitifs de liquidation, donné quitus et décharge de son mandat au liquidateur, {{liquidateurGroup.prenom}} {{liquidateurGroup.nom}}, et constaté la clôture de liquidation à compter du {{acteGroup.dateEffet}}. Formalités au RCS {{societeGroup.societeRcsVille}}.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "acteGroup.dateDu":
            case "acteGroup.dateEffet":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        societeGroup: [{}],
        siegeSocialGroup: [{}],
        liquidateurGroup: [{}],
    },
    schema: [
        {
            //0
            name: "societeGroup",
            label: "La société",
            type: "group",
            value: [{}],
            children: [
                newInput(def.rcs, '_required', {name: 'societeRcs',
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            clotureLiquidation.model.societeGroup[0] = data.values.societe;
                            clotureLiquidation.model.siegeSocialGroup[0] = data.values.siegeSocial;

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            clotureLiquidation.schema[1].children[3].options = dataCP.options;
                            clotureLiquidation.model.siegeSocialGroup[0].societeVille = dataCP.ville_slug;
                        }
                    }
                }),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}),
                newInput(def.sigle),
                newInput(def.formesSocietes, '_required', {name: 'societeFormeJuridique'}),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}),
            ]
        },
        {
            //1
            name: "siegeSocialGroup",
            label: "Siège social",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if (Object.keys(data).length === 0) {
                                return false;
                            }

                            clotureLiquidation.schema[1].children[3].options = data.options;
                            clotureLiquidation.model.siegeSocialGroup[0].ville = data.ville_slug;
                            clotureLiquidation.model.societeGroup[0].lieuImmatriculation = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
            ]
        },
        {
            //2
            name: "acteGroup",
            label: "Acte",
            type: "group",
            value: [{}],
            children: [
                newInput(def.auTermeAssembleDecision, '_required'),
                newInput(def.dateDu, '_required'),
                newInput(def.dateEffet, '_required'),
            ]
        },
        {
            //3
            name: "liquidateurGroup",
            label: "Liquidateur",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nom , '_required'),
                newInput(def.prenom , '_required'),
            ]
        },
    ]
};